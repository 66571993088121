/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

// Jquery needed for some bootsrap components e.g spec.barcode collapse
import './app.bundle'

$(document).ready(function () {
    (function () {
        var a = $('.focus');
        if (a.length) {
            a[0].focus();
        } else {
            a = $('input[type!=password]');
            if (a.length) {
                a[0].focus();
            }
        }
    })();

    var formError = $("#form-error");
    if (formError) {
        formError.fadeTo(7000, 250).slideUp(250, function () {
            formError.alert('close');
        });
    }
});